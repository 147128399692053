import { useState } from "react";
import { sendQuery } from "../ApiActions/ApiActionv1";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "./CustomerSupportScreen.css";

const CustomerSupportScreen = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    query: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      name: formData.name,
      phoneNumber: formData?.phone ?? "",
      email: formData.email,
      query: formData.query,
    };
    const response = await sendQuery(data);
    if (response) {
      alert("Query sent successfully");
      setFormData({
        email: "",
        name: "",
        phone: "",
        query: "",
      });
    } else {
      alert("Failed to send query. Please try again.");
      setFormData({
        email: "",
        name: "",
        phone: "",
        query: "",
      });
    }
  };

  return (
    <div className="support-screen">
      <Header />
      <div className="container">
        <div className="cards-container">
          <div className="card">
            <h3>Call Us</h3>
            <p>Contact us over the given contact numbers.</p>
          </div>
          <div className="card">
            <h3>Email Support</h3>
            <p>Send us an email and we'll get back to you.</p>
          </div>
        </div>

        <form className="support-form">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="+91"
            value={formData.phone}
            onChange={handleChange}
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <label htmlFor="query">Query</label>
          <textarea
            id="query"
            name="query"
            value={formData.query}
            onChange={handleChange}
            required
          />

          <button
            onClick={handleSubmit}
            type="submit"
            className="submit-button"
          >
            Submit
          </button>
        </form>

        <div className="support-info">
          <p>
            <strong>Support Number:</strong> +91 830-880-0004, +91 830-880-0005
          </p>
          <p>
            <strong>Support Email:</strong> <a href="/">support@tubulu.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CustomerSupportScreen;
