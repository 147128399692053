const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.links}>
        <a
          href="https://tubulu.in/privacy-policy/"
          target="_blank"
          style={styles.link}
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        {/* <a href="/terms-of-service" style={styles.link}>
          Terms of Service
        </a> */}
        {/* <a href="/contact-us" style={styles.link}>
          Contact Us
        </a> */}
      </div>
      <div style={styles.copyright}>
        &copy; {new Date().getFullYear()} Tubulu. All rights reserved.
      </div>
    </footer>
  );
};

const styles: any = {
  footer: {
    backgroundColor: "#2355C4",
    color: "white",
    textAlign: "center",
    padding: "20px 0",
    position: "relative",
    bottom: "0",
    width: "100%",
    marginTop: "auto",
  },
  links: {
    marginBottom: "10px",
  },
  link: {
    color: "white",
    margin: "0 15px",
    textDecoration: "none",
    fontSize: "14px",
  },
  copyright: {
    fontSize: "12px",
  },
};

export default Footer;
