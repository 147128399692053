import axios from "axios";
import { apiEndPoints, base_url } from "./environment";

interface IQueryRequestBody {
  readonly name: string;
  readonly email: string;
  readonly phoneNumber?: string;
  readonly query: string;
}

export function sendQuery(data: IQueryRequestBody): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .request({
        method: "POST",
        url: base_url + apiEndPoints.supportForm,
        data,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
