import Logo from "../assets/splash_logo.png";

const Header = () => {
  return (
    <header style={styles.header}>
      <img style={styles.image} src={Logo} alt="logo" />
      <h1 style={styles.title}>Customer Support</h1>
    </header>
  );
};

const styles: any = {
  header: {
    backgroundColor: "#2355C4",
    padding: "20px",
    textAlign: "center",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    margin: "0 0 0 10px",
    fontSize: "24px",
  },
  image: {
    height: "40px",
    width: "35px",
  },
};

export default Header;
